import User from '@customTypes/user';

export function getEmailProvider(provider: string): 'Google' | 'Facebook' | 'Apple' | 'Email' {
  if (provider === 'google_oauth2') {
    return 'Google';
  }
  if (provider === 'facebook') {
    return 'Facebook';
  }
  if (provider === 'apple') {
    return 'Apple';
  }
  return 'Email';
}

export function isUser(value: any): value is User {
  return value && typeof value.id === 'number' && typeof value.email === 'string';
}
export function isErrorDetails(value: any): value is { status: any; errorText: any } {
  return value && typeof value.status !== 'undefined' && typeof value.errorText !== 'undefined';
}
