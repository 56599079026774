/* eslint-disable @typescript-eslint/indent */
import { useContext } from 'react';
import axios from 'axios';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { login as loginEvent, signInWithFacebook, signInWithGoogle } from '@events/Auth';
import { userCreated } from '@events/globals';
import { createUserFromGuest, login, oauthSignUp, signUp, signUpWithName } from '@utils/services';
import { LOGIN, LOG_OUT, OAUTH_BUTTON_CLICK, SIGN_UP } from '@constants/amplitudeEvents';
import { useEvents } from '@events/EventsProvider';
import { signIn, signOut, useSession } from 'next-auth/react';
import User from '@customTypes/user';
import { bookingConfirmationSetupAccount, bookingInvitationSetupAccount } from '@events/bookings';
import { useCookies } from 'react-cookie';
import { deleteCartAndRemoveKeys } from '@helpers/cartFunctions';
import {
  deleteUserImpersonationCookie,
  doesImpersonationCookieExist,
} from '@helpers/userImpersonationFunctions';
import { store } from '@context/store';
import updateLocalUserInformation from '@helpers/updateLocalUserInformation';
import useSessionStorage from './useSessionStorage';

export default function useAuthentication() {
  const ENV = process.env.NEXT_PUBLIC_NEXT_ENV || 'production';
  const { setUserId, track } = useEvents();
  const { getItem, removeItem } = useSessionStorage();
  const session = useSession();
  const [cookies, , removeCookie] = useCookies(['userInformation']);

  const sessionID = getItem(sessionStorageKeys.SESSION_ID);
  const iterableEmail = getItem(sessionStorageKeys.ITERABLE_EMAIL);
  const globalState = useContext(store);
  const accountCreationDate = new Date().toISOString().replace(/T.*/, '');
  const { dispatch } = globalState;

  const handleProviderLogin = (provider: string) => {
    track(OAUTH_BUTTON_CLICK, { social_provider: provider });
    // GA - Events
    if (provider.indexOf('facebook') === 0) {
      signInWithFacebook();
      signIn(provider);
    }

    if (provider.indexOf('google') === 0) {
      signInWithGoogle();
      signIn('google');
    }

    if (provider.indexOf('apple') === 0) {
      signIn('apple');
    }
  };

  const loginUser = async (
    credentials: any,
    cartId?: string,
    sendEvents: boolean = true,
  ): Promise<User | 'error'> => {
    try {
      const data = await login(credentials.username, credentials.password, cartId);
      // eslint-disable-next-line no-extra-boolean-cast
      // Storing the payment methods in the local storage to clear space for the cookie.
      if (data.save_cc_details) {
        window.localStorage.setItem(
          sessionStorageKeys.PAYMENT_METHODS,
          JSON.stringify(data.payment_methods),
        );
      }
      data.payment_methods = [];
      setUserId(data.id?.toString());

      if (sendEvents) {
        // Event
        loginEvent(data);

        // Amplitude
        track(
          LOGIN,
          {
            provider: 'email',
          },
          { withURLandTitle: true },
        );
      }

      return data;
    } catch (err) {
      return 'error';
    }
  };

  const logoutUser = async () => {
    if (session.status === 'authenticated') {
      await signOut();
    }
    const user: User = cookies.userInformation;
    setUserId();
    removeCookie('userInformation', { path: '/' });
    removeItem(sessionStorageKeys.PAYMENT_METHODS);
    if (doesImpersonationCookieExist(`${ENV}_impersonation_jwt`)) {
      deleteUserImpersonationCookie(`${ENV}_impersonation_jwt`);
    }

    if (window.Iterable) {
      window.Iterable.userLogout();
    }
    const cartId = getItem(sessionStorageKeys.CART_ID);
    if (cartId) {
      deleteCartAndRemoveKeys(cartId, dispatch, user);
    }
    track(LOG_OUT, {});
  };

  const oauthLogin = async (
    sess: any,
    cartId?: string,
  ): Promise<User | 'error' | 'registered_with_email'> => {
    try {
      const userInformation = sess.data.token;

      const data = await oauthSignUp(userInformation, cartId);
      data.provider = userInformation.provider; // required.
      // eslint-disable-next-line no-extra-boolean-cast
      // Storing the payment methods in the local storage to clear space for the cookie.
      if (data.save_cc_details) {
        window.localStorage.setItem(
          sessionStorageKeys.PAYMENT_METHODS,
          JSON.stringify(data.payment_methods),
        );
      }
      data.payment_methods = [];

      // Event
      loginEvent(data);
      setUserId(data.id.toString());
      track(LOGIN, {
        provider: data.provider?.toLowerCase(),
      });

      return data;
    } catch (err: any) {
      const errorMessage =
        err.response.data.event === 'registered_with_email' ||
        err.response.data.message === 'email has already been taken'
          ? 'registered_with_email'
          : 'error';

      return errorMessage;
    }
  };

  const userRegistration = async (credentials: any, cartId?: string) => {
    try {
      const response = await signUp(
        credentials.username,
        credentials.password,
        credentials.password_confirmation,
      );

      // Event
      userCreated(response);
      if (response.id) {
        setUserId(response.id.toString());
      }

      track(
        SIGN_UP,
        { provider: 'email', account_creation_date: accountCreationDate },
        { withURLandTitle: true },
      );

      return await loginUser(credentials, cartId, false);
    } catch (err: any) {
      return { status: err?.response?.status, errorText: err?.response?.data?.message };
    }
  };

  const userRegistrationFromGuestBooking = async (
    firstName: string,
    lastName: string,
    newPassword: string,
    bookingAccessId: string | string[],
    setCookie: any,
  ) => {
    const user = await createUserFromGuest(firstName, lastName, newPassword, bookingAccessId);

    // Event
    bookingConfirmationSetupAccount();

    if (window.Iterable) {
      await window.Iterable.updateUserEmail(
        user.email,
        iterableEmail || `${sessionID}@placeholder.email`,
        user,
      );
    }

    if (user.id) {
      setUserId(user.id.toString());
    }

    track(
      SIGN_UP,
      { provider: 'email', account_creation_date: accountCreationDate },
      { withURLandTitle: true },
    );

    await updateLocalUserInformation(user, undefined, setCookie);
  };

  const userRegistrationFromInvite = async (
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    token: string,
    setCookie: any,
  ) => {
    const res = await signUpWithName(email, firstName, lastName, password, password, token);
    // Event
    bookingInvitationSetupAccount();
    if (res.id) {
      setUserId(res.id.toString());
    }
    track(
      SIGN_UP,
      { provider: 'email', account_creation_date: accountCreationDate },
      { withURLandTitle: true },
    );
    await loginUser({ username: email, password }, '', false);
    await updateLocalUserInformation(res, undefined, setCookie);
  };

  const requestResetPasswordEmail = async (username: string) => {
    try {
      const response = await axios(`${process.env.NEXT_PUBLIC_API_BASE_URL}reset_password`, {
        method: 'POST',
        data: {
          user: {
            email: username,
          },
        },
      });
      return response;
    } catch (err) {
      return 'error';
    }
  };

  const resetPassword = async (
    username: string,
    reset_password_token: string,
    password: string,
    password_confirmation: string,
    cartId: string,
  ) => {
    try {
      const response = await axios(`${process.env.NEXT_PUBLIC_API_BASE_URL}apply_password_reset`, {
        method: 'POST',
        data: {
          reset_password_token,
          password,
          password_confirmation,
        },
      });
      if (response.data) {
        const credentials = { username, password };
        return username ? await loginUser(credentials, cartId) : 'password reset success';
      }
      return 'error';
    } catch (err) {
      return 'error';
    }
  };

  return {
    handleProviderLogin,
    loginUser,
    logoutUser,
    oauthLogin,
    requestResetPasswordEmail,
    resetPassword,
    userRegistration,
    userRegistrationFromGuestBooking,
    userRegistrationFromInvite,
  };
}
