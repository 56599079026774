import { store } from '@context/store';
import HotelApi from '@customTypes/hotel-api';
import { HotelApiResponseItem } from '@customTypes/hotel-api-response';
import { getHotelsV5, NewGetHotelsV5 } from '@utils/services';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { useAmplitudeExperimentAB } from '@hooks/useAmplitudeExperiment';

export default function useHomePageHotels() {
  const globalState = useContext(store);
  const { state } = globalState;
  const { userLocation } = state;
  const router = useRouter();

  const [homePageHotels, setHomePageHotels] = useState<HotelApi[]>([]);
  const [newAndNotableHotels, setNewAndNotableHotels] = useState<HotelApi[]>([]);
  const [selectedCity, setSelectedCity] = useState<string>();
  const [selectedCityId, setSelectedCityId] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetchComplete, setIsFetchComplete] = useState<boolean>(false);
  const isUseCaseExperiment = useAmplitudeExperimentAB('use-case-merchandising') === 'treatment';

  const removeDuplicates = (allHotels: HotelApi[]) => {
    const duplicatesRemovedHotels: HotelApi[] = [];
    if (allHotels.length > 0) {
      const hotelIds: number[] = [];
      allHotels.forEach((hotel: HotelApi) => {
        if (hotelIds.indexOf(hotel.id) === -1) {
          duplicatesRemovedHotels.push(hotel);
          hotelIds.push(hotel.id);
        }
      });
    }
    return duplicatesRemovedHotels;
  };

  useEffect(() => {
    const getHotels = async () => {
      try {
        setIsFetchComplete(false);
        setIsLoading(true);

        if (userLocation) {
          const cityId = userLocation.id;
          if (cityId) {
            // Usecase HomePage Hotel Collections
            if (isUseCaseExperiment) {
              // -------------------------------------------------------
              // Near City ---------------------------------------------
              // -------------------------------------------------------
              const hotelDataRequest = NewGetHotelsV5({
                searchType: 'city',
                searchValue: cityId,
                stage: 1,
                limit: 16,
                showOnlyAvailableHotels: true,
              });

              // -------------------------------------------------------
              // New And Notable ---------------------------------------
              // -------------------------------------------------------
              // Hotels created in the last 90 days...
              const dates = (() => {
                const today = new Date();
                const pad = (n: number) => (n < 10 ? `0${n}` : n.toString());
                return Array(...Array(90))
                  .map((_, index) => {
                    if (index === 0) return today;
                    return new Date(new Date().setDate(today.getDate() - index));
                  })
                  .map(
                    (d) =>
                      `created_at:'${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(
                        d.getDate(),
                      )}'`,
                  );
              })();

              // Notable hotel must contain one of these product types:
              // Day Pass, Family Pass , Cabana, Daybed, Pool Chair, Spa Pass,
              // Spa Treatment, Massage, Facial, Nails, Couples Spa
              const productTypeIds = [1, 2, 3, 4, 7, 13, 50, 51, 52, 53, 55].map(
                (i) => `product_type_id:${i}`,
              );

              // find hotels with no ratings (new) or hotels with >= 4.5 (notable)
              const reviewScore = ['rating>=4.5', 'rating=0.0'];
              const newAndNotableDataRequest = NewGetHotelsV5({}, [
                dates,
                productTypeIds,
                reviewScore,
              ]);
              const [hotelData, newAndNotableData] = await Promise.all([
                hotelDataRequest,
                newAndNotableDataRequest,
              ]);
              setHomePageHotels(hotelData.hotels);
              setNewAndNotableHotels(newAndNotableData.hotels);
              return;
            }

            // Default HomePage User Location Collection
            setSelectedCity(userLocation.name);
            setSelectedCityId(cityId);
            const query = `city_id:${cityId}&stage=${1}&page=${0}`;
            const hotelData = await getHotelsV5(query, {});

            if (hotelData && hotelData instanceof Array) {
              const stageOneHotels = (hotelData as HotelApiResponseItem[])[0].hotels.filter(
                (hotel) => hotel.availability && !hotel.closedForSeason,
              );

              if (stageOneHotels.length < 6) {
                const stageTwoHotels = (hotelData as HotelApiResponseItem[])[1].hotels.filter(
                  (hotel) => hotel.availability && !hotel.closedForSeason,
                );
                const stageOneAndTwoHotels = [...stageOneHotels, ...stageTwoHotels];

                if (stageOneAndTwoHotels.length < 6) {
                  const stageThreeHotels = (hotelData as HotelApiResponseItem[])[2].hotels.filter(
                    (hotel) => hotel.availability && !hotel.closedForSeason,
                  );

                  setHomePageHotels(
                    removeDuplicates([
                      ...stageOneHotels,
                      ...stageTwoHotels,
                      ...stageThreeHotels,
                    ]).slice(0, 6),
                  );
                  return;
                }

                setHomePageHotels(removeDuplicates(stageOneAndTwoHotels).slice(0, 6));
                return;
              }

              setHomePageHotels(removeDuplicates(stageOneHotels).slice(0, 6));
            }
          }
        }
      } catch (error) {
        //
      } finally {
        setIsFetchComplete(true);
        setIsLoading(false);
      }
    };
    if (userLocation && (router.pathname === '/' || router.pathname === '/mastercard')) getHotels();
  }, [router.pathname, userLocation, isUseCaseExperiment]);

  return {
    homePageHotels,
    isFetchComplete,
    isLoading,
    selectedCity,
    selectedCityId,
    newAndNotableHotels,
  };
}
