import React, { createContext, useMemo } from 'react';
import HotelApi from '@customTypes/hotel-api';
import useHomePageHotels from '@hooks/useHomePageHotels';

type HomePageHotelsContextType = {
  homePageHotels: HotelApi[];
  newAndNotableHotels: HotelApi[];
  isFetchComplete: boolean;
  isLoading: boolean;
  selectedCity: string | undefined;
  selectedCityId: number | undefined;
};

export const HomePageHotelsContext = createContext<HomePageHotelsContextType>(
  {} as HomePageHotelsContextType,
);

type HomePageHotelsProviderProps = {
  children: React.ReactNode;
};

export function HomePageHotelsProvider({ children }: HomePageHotelsProviderProps) {
  const {
    homePageHotels,
    newAndNotableHotels,
    isFetchComplete,
    isLoading,
    selectedCity,
    selectedCityId,
  } = useHomePageHotels();

  const contextValue = useMemo(
    () => ({
      homePageHotels,
      newAndNotableHotels,
      isFetchComplete,
      isLoading,
      selectedCity,
      selectedCityId,
    }),
    [homePageHotels, newAndNotableHotels, isFetchComplete, isLoading, selectedCity, selectedCityId],
  );

  return (
    <HomePageHotelsContext.Provider value={contextValue}>{children}</HomePageHotelsContext.Provider>
  );
}
